import React, { useEffect, useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import DatePicker from "react-datepicker";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import { token_api } from "../../Utils/Network";
import Snackbar from '@mui/material/Snackbar';
// import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@mui/material/Alert';

const dayOptions = [
  { label: "Sun", value: "sunday" },
  { label: "Mon", value: "monday" },
  { label: "Tue", value: "tuesday" },
  { label: "Wed", value: "wednesday" },
  { label: "Thu", value: "thursday" },
  { label: "Fri", value: "friday" },
  { label: "Sat", value: "saturday" },
];
const batchData = {
  colour: "#D581D6",
  sunday: true,
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: true,
  discount_type: "Percentage",
  commence: moment(),
  conclude: moment(),
  session_name: "",
  price: ""
};

const Setting = () => {

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [data, setData] = useState(batchData);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [open, setOpen] = React.useState(false);
  

  let check = false;

  const format = "h:mm:ss a";
  const now = moment().hour(0).minute(0).seconds(0);
  let coms = moment(data.commence).format("HH:mm:ss");
  let conc = moment(data.conclude).format("HH:mm:ss");
  let starts = moment(startDate).format("DD-MM-YYYY");
  let ends = moment(endDate).format("DD-MM-YYYY");

  var myArray = [];
  const daysList = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  for (const [key, value] of Object.entries(data)) {
    if (typeof value === "boolean") {
      if (value) {
        let getIndex = daysList.indexOf(key) + 1;
        myArray.push(getIndex);
      }
    }
  }

  const demo = {
    session_name: data.session_name,
    price: +data.price,
    start: starts,
    end: ends,
    commence: coms,
    conclude: conc,
    days: myArray,
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const createBatchHandler = () => {
    if (data.session_name == "" && data.price == "") {
      check = false;
      alert("Please fill all the details")
 
    } else {
      check = true;
      // setOpen(true);
    }



    check &&
      token_api
        .post("counseling/v2/all_slots/register_instructor/", demo)
        .then((res) => {

          if (res.status === 200) {
            setOpen(true);
           
          }
        })
        .catch((err) => {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        });
  };

  return (
    <div>
      <div>
        <div>
          <Grid xs={12} container style={{ padding: "2% 5%" }}>
            <Grid
              style={{ paddingLeft: "2%" }}
              container
              spacing={2}
              xs={12}
              md={6}
            >
              <Grid item xs={12}>
                <h2 style={{ color: "#313E6C", margin: 0 }}>
                  Create New Session
                </h2>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  // required
                  id="outlined-required"
                  label="Session Name"
                  variant="outlined"
                  name="session_name"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    setData({ ...data, session_name: e.target.value })
                  }
                  value={data.session_name}
                />
              </Grid>
              <Grid item xs={12} className="weekday-container">
                <p style={{ color: "#313E6C", margin: 0, fontWeight: "bold" }}>
                  Select Days
                </p>
                <Checkbox
                  checked={
                    data.sunday &&
                    data.monday &&
                    data.tuesday &&
                    data.wednesday &&
                    data.thursday &&
                    data.friday &&
                    data.saturday
                  }
                  onChange={(e, v) =>
                    setData({
                      ...data,
                      sunday: v,
                      monday: v,
                      tuesday: v,
                      wednesday: v,
                      thursday: v,
                      friday: v,
                      saturday: v,
                    })
                  }
                  // color="primary"
                  style={{ marginTop: -3, color: "#12878b" }}
                />
                <span>Select all</span>
                <br />
                {dayOptions.map((d) => (
                  <div
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      marginTop: -5,
                    }}
                    key={d.value}
                  >
                    <Checkbox
                      required
                      checked={data[d.value]}
                      style={{ color: "#12878b" }}
                      onChange={(e, checkedState) =>
                        setData({ ...data, [d.value]: checkedState })
                      }
                    />
                    <span>{d.label}</span>
                  </div>
                ))}
                {/* <p className="batch-error-message-c">{errorMessages.errorDay}</p> */}
              </Grid>
              {/* 
              <Button
                color="primary"
                variant="contained"
                style={{
                  width: "100%",
                  margin: "2% 10% 0",
                  borderRadius: "0.6rem",
                  fontSize: "1rem",
                  height: "2.5rem",
                  backgroundColor: "#EF9000" 
                }}
                onClick={createBatchHandler}
              >
                Create
              </Button> 
              */}
            </Grid>
            <Grid
              style={{ paddingLeft: "2%" }}
              container
              spacing={2}
              xs={12}
              md={6}
              sx={{ display: "flex", flexWrap: "wrap", marginTop: "20px" }}
            >
              {/* wrapper box added */}

              <Box sx={{ marginLeft: "10px" }}>
                <Grid style={{ marginTop: "2%" }} item xs={6}>
                  <Box>
                    <Typography sx={{ color: "#696984" }}>
                      Start date
                    </Typography>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </Box>
                  <Box>
                    <Typography sx={{ color: "#696984" }}>End date</Typography>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                    />
                  </Box>
                </Grid>
              </Box>

              <Box sx={{ marginLeft: "10px" }}>
                <Grid style={{ marginTop: "2%" }} item xs={6}>
                  {/* new time picker */}
                  <Box xs={6}>
                    <Typography sx={{ color: "#696984" }}>
                      Start time
                    </Typography>
                    <TimePicker
                      showTodayButton
                      required
                      todayLabel="now"
                      label="Start Time"
                      minutesStep={5}
                      value={data.commence}
                      onChange={(e, v) => setData({ ...data, commence: e })}
                      size="small"
                      inputVariant="outlined"
                      fullWidth
                      autoOk
                    />
                  </Box>

                  <Box xs={6}>
                    <Typography sx={{ color: "#696984" }}>End time</Typography>
                    <TimePicker
                      showTodayButton
                      required
                      todayLabel="now"
                      label="Start Time"
                      minutesStep={5}
                      value={data.conclude}
                      onChange={(e, v) => setData({ ...data, conclude: e })}
                      size="small"
                      inputVariant="outlined"
                      fullWidth
                      autoOk
                    />
                  </Box>
                </Grid>
              </Box>

              <Grid style={{ marginTop: "2%" }} item xs={6}></Grid>
              <Grid style={{ marginTop: "2%" }} item xs={6}></Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="outlined-required"
                  label="Price"
                  variant="outlined"
                  name="price"
                  onChange={(e) => setData({ ...data, price: e.target.value })}
                  value={data.price}
                  type="number"
                  size="small"
                  // error={errors.price ? true : false}
                  // helperText={errors.showError && errors.price}
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Button
            color="primary"
            variant="contained"
            style={{
              width: "12rem",
              margin: "2% 10% 0",
              borderRadius: "0.6rem",
              fontSize: "1rem",
              height: "2.5rem",
              backgroundColor: "#32c6b",
            }}
            onClick={createBatchHandler}
            sx={{ xm: { m: "auto" } }}
          >
            Create
          </Button>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
           <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
             Successfully created
           </Alert>
          </Snackbar>
        </div>
        {/* <button onClick={() => goToPath('/counsellor')}>Back</button> */}
      </div>
    </div>
  );
};

export default Setting;
