import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Rating,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditSessionNotes from "./EditSessionNotes";
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useContext, useRef } from "react";
import StarIcon from "@mui/icons-material/Star";
import { Stack } from "@mui/system";
import { token_api } from "../../Utils/Network";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import EditFieldsForm from "./EditFieldsForm";
import styles from "./students.module.css";
import axios from "axios";
import styled from "@emotion/styled";
import {
  convertdateformat,
  findObject,
  yearMonthDayFormat,
} from "../../Utils/Utils";
import { UserCredsContext } from "../../ContextApis/UserCredsContext/UserCredsContext";
import Tooltip from "@mui/material/Tooltip";
import { gender, editSection } from "../../Utils/Constants";
import SessionComp from "../../Components/SessionComp/SessionComp";
import { session_notesFake } from "../../Components/Fakedata/Fakedata";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { DataObject } from "@mui/icons-material";
import Drawer1 from "../../Components/Drawer1/Drawer1";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import DoneIcon from "@mui/icons-material/Done";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import CustomSnackBar from "../../Components/CustomSnackBar/CustomSnackBar";
import CustomStudentProfile from "../../Components/CustomStudentProfile/CustomStudentProfile";
import CustomDreamCareer from "../../Components/CustomDreamCareer/CustomDreamCareer";
import CustomConcern from "../../Components/CustomConcern/CustomConcern";
import CustomJudgement from "../../Components/CustomJudgement/CustomJudgement";
import CustomRecommendation from "../../Components/CustomRecommendation/CustomRecommendation";
import CustomSessionNotes from "../../Components/CustomSessionNotes/CustomSessionNotes";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import CustomMindDreamCareer from "../../Components/CustomMindDreamCareer/CustomMindDreamCareer";
import DrawerMentalHealth from "../../Components/DrawerMentalHealth/DrawerMentalHealth";

const daysList = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

console.log("fake", session_notesFake);

function Students() {
  const [relation, setRelation] = useState([
    { id: 1, title: "Father" },
    { id: 2, title: "Mother" },
    { id: 3, title: "Brother" },
    { id: 4, title: "Sister" },
    { id: 5, title: "Uncle" },
    { id: 6, title: "Aunt" },
    { id: 7, title: "Niece" },
    { id: 8, title: "Nephew" },
    { id: 9, title: "Cousin" },
    { id: 10, title: "Grandfather" },
    { id: 11, title: "Grandmother" },
    { id: 12, title: "Other" },
  ]);

  const [valueD, setValueD] = React.useState(dayjs(new Date()));

  const [snackbarData, setSnackbarData] = useState({
    message: "",
    open: false,
    responseType: "",
  });

  //ccode for form popup box
  const [dates, setDates] = useState([]);
  const [sessionObject, setSessionObject] = useState({
    id: null,
    language: "",
    dream: "",
    reason_for_referral: "",
    session_notes: {},
  });

  const [registerStudent, setRegisterStudent] = useState({
    user_type: 1,

    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    gender: 0,
  });

  const [profileDetails, setProfileDetails] = useState({
    board_id: null,
    grade_id: null,
    title: "Mr",
    dob: "",
    institute: null,
  });

  const [guardianDetails, setGaurdianDetails] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    relationship: null,
    occupation: "",
    education: "",
  });

  const [open, setOpen] = React.useState(false);
  const [noCash, setNoCash] = useState(false);
  // const [stateForEdit, setStateForEdit] = React.useState(0);
  const [loading, setLoading] = useState(true);
  const [sectionType, setSectionType] = useState(editSection[0]);
  const { gradeList, instituteList } = useContext(UserCredsContext);

  console.log("ins", instituteList);
  const [date, setDate] = useState(null);
  const [testDet, setTestDet] = useState([]);

  const [addSNote, setAddSNote] = useState([]);
  const [displayNotes, setDisplayNotes] = useState([]);
  const [editNotesForm, setEditNotesForm] = useState({
    id: null,
    check: false,
  });

  const [state, setState] = React.useState({
    right: false,
  });

  const [stateID, setStateID] = React.useState(0);

  const [objForForm, setObjForForm] = React.useState({});

  const [editObject, setEditObject] = useState({});

  const [notesState, setNotesState] = useState("");

  const [bookslotData, setBookSlotData] = useState({});

  const [transId, setTransId] = useState(null);

  const [payType, setPayType] = useState(null);

  const [changesMade, setChangesMade] = useState(false);

  const [bookingDate, setBookingDate] = useState(new Date());
  const [pageState, setPageState] = useState(1);
  const name = useRef();
  const email = useRef();
  const phone = useRef();
  const [filterData, setFilterData] = useState({
    searchParam: "",
    grade: "",
    date: "",
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    // const {name, value } = e.target;

    setOpen(false);

    // apicall
  };

  const regex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const reg = /^\d+$/;

  const regName = /^[A-Za-z]+$/;

  const regMobile = /^\d{10}$/;

  const [checkPass, setCheckPass] = useState("");
  const [checkNumber, setCheckNumber] = useState(false);

  const [checkMail, setCheckMail] = useState(false);
  const [checkUserName, setCheckUserName] = useState(false);
  const [checkGrade, setCheckGrade] = useState(false);
  const [checkInstitute, setCheckInstitute] = useState(false);
  const [checkGname, setCheckGname] = useState(false);
  const [checkGphone, setCheckGphone] = useState(false);
  const [checkGeMail, setCheckGeMail] = useState(false);
  const [opneMental, setOpenMental] = useState(false);
  const [mentalHealthData, setMentalHealthData] = useState({});

  const handleStuPost = () => {
    if (alreadyRegister) {
      alert("User is already registered");
      return;
    }

    if (reg.test(guardianDetails.phone)) {
      if (guardianDetails.phone.length == 10) {
        setCheckGphone(false);
      }
    } else {
      setCheckGphone(true);
    }

    if (
      regName.test(guardianDetails.first_name) &&
      regName.test(guardianDetails.last_name)
    ) {
      setCheckGname(false);
    } else {
      setCheckGname(true);
    }

    if (profileDetails.grade_id == null) {
      setCheckGrade(true);
    } else {
      setCheckGrade(false);
    }

    if (profileDetails.institute == null) {
      setCheckInstitute(true);
    } else {
      setCheckInstitute(false);
    }

    if (
      regName.test(registerStudent.first_name) &&
      regName.test(registerStudent.last_name)
    ) {
      setCheckUserName(false);
    } else {
      setCheckUserName(true);
    }

    // if (reg.test(registerStudent.phone) && registerStudent.phone.length !== 0 ) {
    //   if (registerStudent.phone.length == 10) {
    //     setCheckNumber(false)
    //   } else {
    //     setCheckNumber(true);
    //   }
    // }
    // else{
    //   setCheckNumber(true)
    // }

    if (regex.test(registerStudent.email)) {
      setCheckMail(false);
    } else {
      setCheckPass("Enter valid email address");
      setCheckMail(true);
    }

    token_api
      .post(`/auth/v2/lcarro/student_register_by_counselor/`, {
        ...registerStudent,
        profile: { ...profileDetails },
        guardian: [{ ...guardianDetails }],
      })
      .then((response) => {
        console.log("Checking Response after post", response.data.data);
        setDataAddStu(false);
      });

    console.log("opop", {
      ...registerStudent,
      profile: { ...profileDetails },
      guardian: [{ ...guardianDetails }],
    });
  };

  const handleDate = (_date) => {
    setValueD(_date);

    console.log("datessss", _date);
    setFilterData({ ...filterData, date: _date });
    getStudentsList(filterData.searchParam, filterData.grade, _date);
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getStudentsList(
        filterData.searchParam,
        filterData.grade,
        filterData.date
      );
    }, 600);

    return () => clearTimeout(delayDebounceFn);
  }, [filterData.searchParam]);

  console.log("OBjeForForm", objForForm);

  //  edit function click
  // const editFunctionClicked = (string, objForEdit,anchor, bool) => {

  //   handleClickOpen(string, objForEdit);
  //   toggleDrawer(anchor, true)

  // }

  // useEffect(() => {
  //   token_api
  //     .get(`/counseling/all_slots/slots/?date=16-09-2022`)
  //     .then((response) => {
  //       setDates(response.data.data);
  //     });
  // }, []);

  const openTestPdf = (e, id, testtype) => {
    // console.log("idpdf", id);
    // console.log("typepdf",testtype);

    token_api
      .get(`/counseling/overall_result/?user_id=${id}&test_type=${testtype}`)
      .then((response) => {
        console.log(response.data.data.pdf);
        response.data.data.pdf
          ? window.open(response.data.data.pdf)
          : alert("User has not given the test");
      });
  };

  const handlesNotesSubmit = (e, element, ses, notesState) => {
    e.preventDefault();
    // console.log("ses", ses);
    // console.log("ele", element);
    let data = {};
    let size = 1;

    // console.log("nnn11", notesState);
    // console.log("sesObSUB", sessionObject);

    // let sData = getSessionData()
    // console.log("ss13", sData);

    data = {
      user_id: element.id,
      session: [
        {
          id: ses.id,
          session_notes: {
            session_field2: notesState,
          },
        },
      ],
    };

    console.log("Data45", data);
  };

  const handleSessionNotesEdit = (index, check, ses) => {
    console.log("entered", index);

    setEditNotesForm({
      ...editNotesForm,
      id: index,
      check,
    });
    setSessionObject({
      ...sessionObject,
      id: ses.id,
    });
  };
  console.log("lll", editNotesForm);

  console.log("EditNo", sessionObject);
  console.log("nnn", notesState);

  // to open pop up box
  const [dataAddStu, setDataAddStu] = useState(false);
  const [paymentState, setPaymentState] = useState(false);
  const handleCreateSession = (string) => {
    setDataAddStu(true);
    // console.log("handleCreateSession");
    // setOpen(true);
    // // setStateForEdit(id);
    // const retObj = findObject(editSection, string, "type");
    // console.log("ret", retObj);

    // setSectionType(retObj);
    // setEditObject({});
    // setObjForForm({});
  };
  const [payment, setPayment] = React.useState("");
  const [gradeVal, setGradeVal] = useState("");
  const [dateNew, setDateNew] = useState(new Date());
  const [correctDate, setCorrectDate] = useState(null);

  useEffect(() => {
    const dateCorrect = moment(dateNew).format("Y-MM-D");
    console.log(dateCorrect);
    setCorrectDate(dateCorrect);
    // handleChangeProfileDate();
  }, [dateNew]);

  console.log("dateC", correctDate);

  const handleChangeProfileDate = (date) => {
    // const [dateString] = date.toISOString().split("T");
    // console.log("dda", date.toLocaleDateString());
    // console.log("dateString", dateString);
    // var dateMod = convertdateformat(date.toLocaleDateString())
    //    console.log("fre",dateMod);
    // const dateCorrect = moment(date).format("Y-MM-D");
    // console.log("ooo", moment(date).format("Y-MM-D"));

    const dateCorrect = moment(date).format("Y-MM-D");
    console.log("qqqe", dateCorrect);
    // console.log("dateC2", correctDate);
    setCorrectDate(dateCorrect);

    setProfileDetails({
      ...profileDetails,
      dob: dateCorrect,
    });
  };

  console.log("pou", profileDetails);

  const [checkNumberSize, setCheckNumberSize] = useState(false);
  const [fakeDataF, setFakeDataF] = useState([]);
  const [alreadyRegister, setAlreadyRegister] = useState(null);

  const [registerStudentRev, setRegisterStudentRev] = useState({
    user_type: 1,

    first_name: "",
    last_name: "",
    // email: "",
    phone: "",
    gender: 0,
  });

  const [profileDetailsRev, setProfileDetailsRev] = useState({
    board_id: null,
    grade_id: null,
    title: "Mr",
    dob: "",
    institute: null,
  });

  const [guardianDetailsRev, setGaurdianDetailsRev] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    relationship: null,
    occupation: "",
    education: "",
  });

  const [checkDob, setCheckDob] = useState(false);

  const handleChangeAdd = (e) => {
    const { name, value } = e.target;

    if (alreadyRegister == true) {
      setChangesMade(true);
    }

    setRegisterStudentRev({
      ...registerStudentRev,
      [name]: value,
    });

    setProfileDetailsRev({
      ...profileDetailsRev,
      [name]: value,
    });

    setGaurdianDetailsRev({
      ...guardianDetailsRev,
      [name]: value,
    });
    // const reg = /^\d+$/;

    // setAlreadyRegister(null);

    console.log("leng", value.length);

    if (name === "first_name" || name === "last_name") {
      if (regName.test(value)) {
        setCheckUserName(false);
      } else {
        setCheckUserName(true);
      }
    }

    if (name === "phone") {
      if (value.length < 10 && alreadyRegister != null) {
        console.log("iiiii", value.length);
        if (alreadyRegister == false) {
          setRegisterStudent({
            ...registerStudent,
            first_name: registerStudentRev?.first_name,
          });
        } else {
          setRegisterStudent({
            ...registerStudent,
            first_name: "",
            last_name: "",
            email: "",
            gender: "",
            phone: value,
          });

          setProfileDetails({
            ...profileDetails,
            board_id: null,
            grade_id: null,

            dob: "",
            institute: null,
          });

          setGaurdianDetails({
            ...guardianDetails,
            first_name: "",
            last_name: "",
            phone: "",
            email: "",
            relationship: null,
            occupation: "",
            education: "",
          });

          return;
        }
      }

      if (regMobile.test(value)) {
        // if (value.length <= 10) {
        //   setCheckNumberSize(false);
        // }
        // else{
        //   setCheckNumberSize(true);

        // }

        setCheckNumber(false);

        token_api
          .get(`/auth/v2/lcarro/unique_phone_check/?phone=${value}`)
          .then((response) => {
            console.log("chekResponse", response.data);
            if (response.data.data.status) {
              setAlreadyRegister(false);

              setRegisterStudent({
                ...registerStudent,
                first_name: registerStudentRev?.first_name,
                last_name: registerStudentRev?.last_name,

                gender: registerStudentRev?.gender,
                phone: value,
              });

              setProfileDetails({
                ...profileDetails,
                board_id: profileDetailsRev?.board_id,
                grade_id: profileDetailsRev?.grade_id,

                dob: profileDetailsRev?.dob,
                institute: profileDetailsRev?.institute,
              });
              return;

              // setGaurdianDetails({
              //   ...guardianDetails,
              //   first_name: guardianDetailsRev?.first_name,
              //   last_name: guardianDetailsRev?.last_name,
              //   phone: guardianDetailsRev?.phone,
              //   email: guardianDetailsRev?.email,
              //   relationship: setGaurdianDetailsRev?.relationship,
              //   occupation: guardianDetailsRev?.occupation,
              //   education: guardianDetailsRev?.education,
              // });
            } else {
              setAlreadyRegister(true);
              setUserIdBook(response.data.data?.id);
              setRegisterStudent({
                ...registerStudent,
                user_type: 1,
                id: response.data.data?.id,
                first_name: response.data.data?.first_name,
                last_name: response.data.data?.last_name,
                email: response.data.data?.email,

                phone: response.data.data?.phone,
                gender: response.data.data?.gender,
              });

              setProfileDetails({
                ...profileDetails,
                board_id: response.data.data?.profile?.board?.id,
                grade_id: response.data.data?.profile?.grade?.id,

                dob: response.data.data?.profile?.dob,
                institute: response.data.data?.profile?.institute,
              });

              setGaurdianDetails({
                ...guardianDetails,
                first_name: response.data.data?.guardian[0]?.first_name,
                last_name: response.data.data?.guardian[0]?.last_name,
                phone: response.data.data?.guardian[0]?.phone,
                email: response.data.data?.guardian[0]?.email,
                relationship: response.data.data?.guardian[0]?.relationship,
                occupation: response.data.data?.guardian[0]?.occupation,
                education: response.data.data?.guardian[0]?.education,
              });
            }
          });

        return;
      } else {
        setCheckNumber(true);
      }
    }

    if (name === "email") {
      if (regex.test(value)) {
        setCheckMail(false);
      } else {
        setCheckPass("Enter valid email address");
        setCheckMail(true);
      }
    }

    setRegisterStudent({
      ...registerStudent,
      [name]: value,
    });
  };

  console.log("lastN", registerStudent);

  const handleCallBack = (e) => {
    console.log("callBack", e);
    listData = listData.findIndex((item, idex) => {
      if (item.id == e.id) {
        return (listData[idex] = e);
      }
    });

    //  console.log("llllll" , listData);
  };

  const handleChangeGuardian = (e) => {
    const { name, value } = e.target;

    if (name === "first_name" || name === "last_name") {
      if (regName.test(value)) {
        setCheckGname(false);
      } else {
        setCheckGname(true);
      }
    }

    if (name === "phone") {
      if (regMobile.test(value)) {
        // if (value.length <= 10) {
        //   setCheckNumberSize(false);
        // } else {
        //   setCheckNumberSize(true);
        // }
        setCheckGphone(false);
      } else {
        setCheckGphone(true);
      }
    }

    if (name === "email") {
      if (regex.test(value)) {
        setCheckGeMail(false);
      } else {
        setCheckPass("Enter valid email address");
        setCheckGeMail(true);
      }
    }

    setGaurdianDetails({
      ...guardianDetails,
      [name]: value,
    });
  };
  console.log("gurd", guardianDetails);

  const handleChangeProfile = (e) => {
    const { name, value } = e.target;

    setProfileDetails({
      ...profileDetails,
      [name]: value,
    });
  };

  console.log("pro", profileDetails);

  console.log("regit", registerStudent);

  const handlePutTransID = (e) => {
    console.log("tt", e.target.value);
    setTransId(e.target.value);
  };

  console.log("tram", transId);

  const handleChangePayment = (event) => {
    setPayment(event.target.value);
    console.log("pay", event.target.value);
    if (event.target.value === "cash") {
      setNoCash(false);
      setTransId("");
      setPayType(5);
    } else {
      if (event.target.value === "upi") {
        setNoCash(true);
        setPayType(1);
      }
      if (event.target.value === "neft") {
        setNoCash(true);
        setPayType(3);
      }
      if (event.target.value === "cheque") {
        setNoCash(true);
        setPayType(4);
      }
    }
  };
  const [nowCheck, setNowCheck] = useState(false);
  const addStuDate = (date) => {
    var dateMod = date.toLocaleDateString();
    setBookingDate(dateMod);
    console.log("ate", date);
    const { month, day, year } = yearMonthDayFormat(date);
    // console.log("day", day+1);
    // console.log(moment(date).format('dddd MMMM D Y'));
    setNowCheck(true);

    token_api
      .get(`/counseling/all_slots/slots/?date=${day}-${month}-${year}`)
      .then((response) => {
        console.log("dateslot", response.data.data);
        setDates(response.data.data);
      });
  };

  const [userIdBook, setUserIdBook] = useState("");

  const addstuAndPayAndBook = () => {
    let goodTogo = true;
    // if (reg.test(guardianDetails.phone)) {
    //   if (guardianDetails.phone.length == 10) {
    //     setCheckGphone(false);
    //   }
    // } else {
    //   setCheckGphone(true);

    // }

    // {alreadyRegister ? setDataAddStu(false) :  setPaymentState(true)}
    console.log("alr", alreadyRegister);
    if (alreadyRegister == true) {
      console.log("logged");
      {
        changesMade == true
          ? token_api
              .post(`/counseling/v2/all_slots/update_student_profile/`, {
                user_id: userIdBook,
                user: {
                  first_name: registerStudent.first_name,
                  last_name: registerStudent.last_name,
                  gender: registerStudent.gender,
                },
                profile: { ...profileDetails },
                guardian: [{ ...guardianDetails }],
              })
              .then((response) => {
                console.log("Checking Response after post", response.data.data);
              })
          : setChangesMade(false);
      }

      setDataAddStu(false);
      setPaymentState(true);
    } else {
      if (
        regName.test(guardianDetails.first_name) &&
        regName.test(guardianDetails.last_name)
      ) {
        setCheckGname(false);
      } else {
        setCheckGname(true);
      }

      if (profileDetails.grade_id == null) {
        setCheckGrade(true);
      } else {
        setCheckGrade(false);
      }

      if (profileDetails.institute == null) {
        setCheckInstitute(true);
      } else {
        setCheckInstitute(false);
      }

      if (
        regName.test(registerStudent.first_name) &&
        regName.test(registerStudent.last_name)
      ) {
        setCheckUserName(false);
      } else {
        setCheckUserName(true);
      }

      let chGo = false;

      if (regMobile.test(registerStudent.phone)) {
        setCheckNumber(false);
      } else {
        chGo = true;
        setCheckNumber(true);
      }

      // if (profileDetails.dob.length != 0) {
      //   setCheckDob(false);
      // } else {
      //   setCheckDob(true);
      //   chGo = true;
      // }

      // if (
      //   registerStudent.phone.length !== 0 &&
      //   reg.test(registerStudent.phone)
      // ) {
      //   if (registerStudent.phone.length == 10) {
      //     setCheckNumber(false);
      //   } else {
      //     chGo = true;
      //     setCheckNumber(true);
      //   }
      // } else {
      //   chGo = true;
      //   setCheckNumber(true);
      // }

      // if (
      //   regex.test(registerStudent.email) &&
      //   registerStudent.email.length !== 0
      // ) {
      //   setCheckMail(false);
      // } else {
      //   setCheckPass("Enter valid email address");
      //   setCheckMail(true);
      //   // Checkmail = false;
      // }

      if (chGo) {
        goodTogo = false;
      }

      console.log("goodtogohh", goodTogo);

      {
        goodTogo
          ? token_api
              .post(`/auth/v2/lcarro/student_register_by_counselor/`, {
                ...registerStudent,
                profile: { ...profileDetails },
                guardian: [{ ...guardianDetails }],
              })
              .then((response) => {
                console.log("Checking Response after post", response.data.data);
                setUserIdBook(response.data.data.user.id);
              })
          : alert("Enter Details properly");
      }
      // {checkMail == true ?  setPaymentState(false): setPaymentState(true)}
      {
        goodTogo ? setDataAddStu(false) : setDataAddStu(true);
      }

      {
        goodTogo ? setPaymentState(true) : setPaymentState(false);
      }
    }
  };

  console.log("setuseId", userIdBook);

  const [colButton, setColButton] = useState(false);

  const handleNowTime = () => {
    let newDate = new Date();
    // console.log("newDate", newDate);
    let day = moment().format("dddd");
    let time = moment().format("");
    let starts = moment().format("DD-MM-YYYY");
    let ends = moment().format("DD-MM-YYYY");
    let coms = moment().format("HH:mm:ss");
    let conc = moment().add(1, "hour").format("HH:mm:ss");
    console.log("day", day);
    // console.log("time",time);
    console.log("starts", day, time, starts, ends, coms, conc);
    const dayNum = daysList.findIndex((val) => {
      return val == day;
    });
    console.log("dayNUm", dayNum);

    const demo = {
      start: starts,
      price: 500,
      end: ends,
      commence: coms,
      conclude: conc,
      days: [+dayNum + 1],
    };

    console.log("demo", demo);
    setColButton(true);

    // let now = moment(newDate).format("DD MM YYYY")
    // let newdate2 = moment().format('MMMM Do YYYY, h:mm:ss a');
    // console.log( "qqqq",moment().format('dddd MMMM Do YYYY, h:mm:ss a'));

    token_api
      .post("counseling/v2/all_slots/register_instructor/", demo)
      .then((res) => {
        console.log("res dd", res.data.data);
        setBookSlotData(res.data.data);
      });
  };

  const [valTrans, setValTrans] = useState(false);
  const [payAlert, setPayAlert] = useState(false);

  const handleBookingFree = (data) => {
    setAlreadyRegister(null);
    setCheckDob(false);

    setRegisterStudent({
      ...registerStudent,
      first_name: "",
      last_name: "",
      email: "",
      gender: "",
      phone: "",
    });

    setProfileDetails({
      ...profileDetails,
      board_id: null,
      grade_id: null,

      dob: correctDate,
      institute: null,
    });

    setGaurdianDetails({
      ...guardianDetails,
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      relationship: null,
      occupation: "",
      education: "",
    });

    setColButton(false);
    if (noCash == true && transId.length === "0") {
      setValTrans(true);
    }
    console.log("datains", data);
    console.log(payment.length, "pay123");

    if (payment.length == 0) {
      // alert("Set Payment method")
      setPayAlert(true);
      alert("Error");
      return;
    } else {
      console.log("come here", valTrans);
      // alert("in else")
      valTrans == false
        ? token_api
            .post(`/counseling/purchase/free_booking_by_counselor/`, {
              slot_id: data.instructor_slots[0]?.id,
              user: userIdBook,
              transaction_id: transId,

              payment_method: payType,
            })
            .then((response) => {
              console.log("Response for book", response);

              if (response.status == 200) {
                setSnackbarData({
                  message: "Session has been booked",
                  open: true,
                  responseType: "success",
                });
              } else {
                setSnackbarData({
                  message: "Session has not been booked",
                  open: true,
                  responseType: "error",
                });
              }

              const timeOut = setTimeout(handleOpen, 3000);
              function handleOpen() {
                setSnackbarData({
                  message: "",
                  open: false,
                  responseType: "success",
                });
              }
            })
            .catch((error) => {
              console.log("ErrorBook", error);
            })
        : alert("Enter transaction Id");

      console.log("obpay", {
        slot_id: data.instructor_slots[0]?.id,
        user: userIdBook,
        transaction_id: transId,

        payment_method: payType,
      });

      setPaymentState(false);
    }
  };

  const addstuAndPayClose = () => {
    setCheckNumber(false);
    setAlreadyRegister(null);

    setRegisterStudent({
      ...registerStudent,
      first_name: "",
      last_name: "",
      email: "",
      gender: "",
      phone: "",
    });
    setProfileDetails({
      ...profileDetails,
      board_id: null,
      grade_id: null,

      dob: "",
      institute: null,
    });

    setGaurdianDetails({
      ...guardianDetails,
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      relationship: null,
      occupation: "",
      education: "",
    });

    setDataAddStu(false);
  };

  const addstuAndPayCloseAfterPayment = () => {
    setPaymentState(false);
  };

  const handleClickOpen = (string, objForEdit) => {
    setOpen(true);
    // setStateForEdit(id);
    const retObj = findObject(editSection, string, "type");
    console.log("ret", retObj);

    setSectionType(retObj);
    setEditObject(objForEdit);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const openSetObjForm = (itemObject) => {
  //   setObjForForm(itemObject);
  // };

  const handleEditIconFunction = () => {
    console.log("Entered edit");
  };

  const handleAddNotes = (index, element, obj, user) => {
    console.log("Add notes", obj);
    console.log(index, "ind");
    console.log("eleAddNote33", element);
    console.log("userId", user);

    let id;

    let size = 0;
    if (obj.session_notes === null) {
      size = 0;
      console.log("size1", size);
    } else {
      size = Object.keys(obj.session_notes).length;
      console.log("size", size);
    }

    // var size = Object.keys(session_notesFake).length;

    let e = (
      <Card
        sx={{
          backgroundColor: "#FFFFFF",
          color: "#696984",
          border: "1px solid #7E7E7E",
          width: "90%",
          marginTop: "10px",
        }}
      >
        <CardContent>
          <Box>
            <Typography>
              {/* Session Field {size +1} */}
              Note {size + 1}
              {/* <EditIcon
                marginLeft="100%"
                onClick={() => {
                  handleSessionNotesEdit(size, true, obj);
                }}
              /> */}
              <EditSessionNotes id={obj.id} userId={user.id} add={true} />
            </Typography>
          </Box>
        </CardContent>
      </Card>
    );

    setAddSNote([
      ...addSNote,
      {
        id: obj.id,
        e,
      },
    ]);
  };

  const handleCareerCounsellingReport = (userId, reportType) => {
    console.log("CareerResporttyepe", reportType);

    if (reportType == false) {
      // call online
      token_api
        .get(
          `/counseling/career/report/career_counselling_report/?user_id=${userId}`
        )
        .then((response) => {
          console.log("pdfResponse", response);
          window.open(response.data.data.file);
        })
        .catch((err) => {
          alert("Error!Report cannot be generated");
        });
    } else if (reportType == true) {
      // call offline
      token_api
        .get(
          `counseling/career/report/career_counselling_offline_report/?user_id=${userId}`
        )
        .then((response) => {
          console.log("OfflineResport", response);
          if (response.data.data.file) {
            window.open(response.data.data.file);
          } else {
            alert("Error! Report does not exist");
          }
        })
        .catch((err) => {
          alert("Error! Report does not exist");
        });
    }
  };

  const toggleDrawerOne = (anchor, open, string, objForEdit) => (event) => {
    setState({ ...state, [anchor]: open });
    console.log("ppp", objForEdit);

    handleClickOpen(string, objForEdit);
  };

  const toggleDrawer = (anchor, open, id, itemObject) => (event) => {
    setState({ ...state, [anchor]: open });
    setStateID(id);
    setObjForForm(itemObject);

    token_api
      .get(`/counseling/test_result/?user_id=${id}`)
      .then((response) => setTestDet(response.data));
    // setTestDet(response.data)
  };
  console.log("testD", testDet);
  console.log("obb22", objForForm);

  const list = (anchor, id, obj) => {
    const newArray = listData.filter((element) => {
      return element.id === id;
    });

    return (
      <Box className={styles.moreInfo} role="presentation">
        {newArray.map((element) => {
          return (
            <Box>
              <Box
                sx={{
                  width: "90%",
                  margin: "auto",
                }}
              >
                <Stack direction="row" sx={{ paddingBottom: "25px" }}>
                  <Typography
                    sx={{
                      display: "inline-block",
                      fontSize: "1.25em",
                      color: "#696984",
                      marginLeft: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    {element.first_name + " " + element.last_name}
                  </Typography>
                  <Box
                    sx={{
                      position: "absolute",
                      right: "10px",
                      height: "20px",
                      width: "100px",
                    }}
                  >
                    <div className={styles.iconStyles}>
                      {" "}
                      <EditIcon
                        sx={{
                          marginLeft: "40%",
                          fontSize: "17.5px",
                          display: "inline-block",
                          cursor: "pointer",
                        }}
                        onClick={toggleDrawerOne(
                          "right",
                          true,
                          "userDetails",
                          element.profile
                        )}
                      />
                      <CloseIcon
                        className={styles.pointer}
                        onClick={toggleDrawer(anchor, false)}
                      />
                    </div>
                    <Stack direction="row" spacing={1}></Stack>
                  </Box>
                </Stack>

                <Box
                  sx={{
                    width: "95%",
                    margin: "auto",
                    color: "#696984",
                    fontSize: "1em",
                  }}
                >
                  <Stack direction="row" spacing={5}>
                    <Box>
                      <Typography>
                        Grade: {element.profile?.grade?.title}
                      </Typography>
                      <Typography>
                        Gender:{" "}
                        {
                          gender[
                            gender.findIndex(
                              (item) => item.id == element.gender
                            )
                          ]?.name
                        }
                      </Typography>
                    </Box>
                    <Box>
                      <Typography>
                        Institute:{" "}
                        {
                          instituteList[
                            instituteList.findIndex(
                              (item) => item.id == element.profile?.institute
                            )
                          ]?.name
                        }
                      </Typography>
                      <Typography>DOB: {element.profile?.dob}</Typography>
                    </Box>
                  </Stack>
                </Box>
              </Box>

              <Box
                sx={{
                  width: "28.5em",
                  margin: "auto",
                  color: "#696984",
                }}
              >
                <Typography
                  sx={{
                    marginTop: "15px",
                    marginBottom: "15px",
                    fontSize: "1.125em",
                    color: "#696984",
                    fontWeight: "bold",
                  }}
                >
                  Guardian Info:{" "}
                  {element.guardian.length == 0 ? (
                    <Typography>No Guardian Info available.</Typography>
                  ) : null}
                  {/* <EditIcon
                    onClick={() => handleClickOpen(element.guardian[0])}
                  /> */}
                </Typography>
                <Stack direction="column" spacing={3}>
                  {element.guardian.map((gE, indiex) => {
                    return (
                      <Card
                        sx={{
                          backgroundColor: "#FFFFFF",
                          color: "#696984",
                          border: "1px solid #7E7E7E",
                          paddingTop: "5px",
                          width: "auto",
                        }}
                      >
                        <CardContent color="#696984" sx={{ paddingTop: "2px" }}>
                          <EditIcon
                            sx={{
                              marginLeft: "98%",
                              fontSize: "15px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleClickOpen("guardianDetails", gE)
                            }
                          />
                          <Stack direction="row" spacing={4}>
                            <Box sx={{ width: "100%" }}>
                              <Stack direction="column" spacing={1}>
                                <Typography>
                                  Name:{" "}
                                  {gE
                                    ? gE.first_name + " " + gE.last_name
                                    : "-"}
                                </Typography>
                                <Typography>
                                  Email: {gE ? gE.email : "-"}
                                </Typography>
                                <Typography>
                                  Education: {gE ? gE.education : "-"}
                                </Typography>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "100%" }}>
                              <Stack direction="column" spacing={1}>
                                <Typography>
                                  Mobile: {gE ? gE.phone : "-"}
                                </Typography>
                                <Typography>
                                  Occupation: {gE ? gE.occupation : "-"}
                                </Typography>
                              </Stack>
                            </Box>
                          </Stack>
                        </CardContent>
                      </Card>
                    );
                  })}
                </Stack>
              </Box>

              <Box
                sx={{
                  marginTop: "20px",
                  width: "28.5em",
                  margin: "auto",
                  color: "#696984",
                }}
              >
                <Typography
                  sx={{
                    display: "inline-block",
                    marginTop: "15px",
                    marginBottom: "15px",
                    fontSize: "1.125em",
                    color: "#696984",
                    fontWeight: "bold",
                  }}
                >
                  Address:{" "}
                  {element.address.length == 0 ? (
                    <Typography>Adress not available.</Typography>
                  ) : null}
                </Typography>

                {element.address.length == 0
                  ? null
                  : element.address.map((addEle) => {
                      return (
                        <Card
                          sx={{
                            backgroundColor: "#FFFFFF",
                            color: "#696984",
                            border: "1px solid #7E7E7E",
                            marginTop: "15px",
                          }}
                        >
                          <CardContent>
                            <EditIcon
                              sx={{
                                fontSize: "15px",
                                display: "inline-block",
                                cursor: "pointer",
                                marginLeft: "98%",
                                fontSize: "15px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleClickOpen("addressDetails", addEle)
                              }
                            />

                            <Stack direction="row" spacing={4}>
                              <Box sx={{ width: "190px" }}>
                                <Stack direction="column" spacing={1}>
                                  <Typography>
                                    Landmark:
                                    {addEle ? addEle.landmark : "-"}
                                  </Typography>
                                  <Typography>
                                    City:
                                    {addEle ? addEle.city : "-"}
                                  </Typography>
                                </Stack>
                              </Box>
                              <Box sx={{ width: "190px" }}>
                                <Stack direction="column" spacing={1}>
                                  <Typography>
                                    Pincode:
                                    {addEle ? addEle.zipcode : "-"}
                                  </Typography>
                                  <Typography>
                                    State:
                                    {addEle ? addEle.state : "-"}
                                  </Typography>
                                </Stack>
                              </Box>
                            </Stack>
                          </CardContent>
                        </Card>
                      );
                    })}
              </Box>

              <Box
                sx={{
                  width: "28.5em",
                  margin: "auto",
                  color: "#696984",
                }}
              >
                <Typography
                  sx={{
                    marginTop: "15px",
                    marginBottom: "15px",
                    fontSize: "1.125em",
                    color: "#696984",
                    fontWeight: "bold",
                  }}
                >
                  Tests Results:{" "}
                  {testDet.length === 0 && "No test details available"}
                </Typography>

                <Card
                  sx={{
                    backgroundColor: "#FFFFFF",
                    color: "#696984",
                    border: "1px solid #7E7E7E",
                    paddingTop: "5px",
                    width: "auto",
                  }}
                  className={testDet.length === 0 && styles.displayCardNone}
                >
                  {testDet.map((testData, index) => {
                    console.log("666", testDet);

                    return (
                      <CardContent
                        color="#696984"
                        sx={{ paddingTop: "0px", paddingBottom: "0px" }}
                      >
                        <Box>
                          <Typography
                            className={styles.capLetter}
                            onClick={(e) =>
                              openTestPdf(e, element.id, testData.TestType)
                            }
                          >
                            {testData.TestType === "achievement" ? (
                              <p className={styles.textHover}>Academics:</p>
                            ) : (
                              <p className={styles.textHover}>
                                {testData.TestType}:
                              </p>
                            )}

                            <div className={styles.testBoxGroup}>
                              {testData.data.length == 0 ? (
                                "No details available"
                              ) : (
                                <>
                                  <Button size="small" variant="contained">
                                    View Report
                                  </Button>
                                </>
                              )}
                            </div>
                            {/* <div className={styles.testBoxGroup}>
                              {testData.data.length == 0
                                ? "No Details Available"
                                : testData.data.map((testSingleData, index) => {
                                    console.log("sing", testSingleData);
                                    return (
                                      // <div
                                      //   className={
                                      //     testSingleData.submitted == true
                                      //       ? styles.testBox
                                      //       : styles.testBoxFail
                                      //   }
                                      //   // onClick={(e) =>
                                      //   //   openTestPdf(
                                      //   //     e,
                                      //   //     element.id,
                                      //   //     testSingleData.test_type
                                      //   //   )
                                      //   // }
                                      // >
                                      //   {testSingleData.test_name}www
                                      // </div>

                                      <Button variant="contained">View Report</Button>
                                    );
                                  })}
                            </div> */}
                          </Typography>
                        </Box>
                      </CardContent>
                    );
                  })}
                </Card>
              </Box>

              <Box
                sx={{
                  width: "28.5em",
                  margin: "auto",
                  color: "#696984",
                }}
              >
                <Typography
                  sx={{
                    marginTop: "15px",
                    marginBottom: "15px",
                    fontSize: "1.125em",
                    color: "#696984",
                    fontWeight: "bold",
                  }}
                >
                  Sessions [{element.sessions.length}]
                </Typography>

                {element.sessions.map((sessElement, index) => {
                  console.log("sss1234", sessElement);
                  return (
                    <Card
                      sx={{
                        backgroundColor: "#FFFFFF",
                        color: "#696984",
                        border: "1px solid #7E7E7E",
                        marginBottom: "10px",
                      }}
                    >
                      <CardContent sx={{ paddingTop: "9px" }}>
                        <Stack direction="row" spacing={3}>
                          <Box sx={{ width: "190px" }}>
                            <Stack direction="column" spacing={1}>
                              <Typography>Session: {index + 1}</Typography>
                              <Typography>
                                Counsellor:{" "}
                                {sessElement ? sessElement.counsellor : "-"}
                              </Typography>

                              <div className={styles.ratings}>
                                <p className={styles.rateName}>Rating:</p>
                                <p className={styles.rateFontSize}>
                                  {" "}
                                  <Rating
                                    name="read-only"
                                    value={sessElement.review?.rating}
                                    readOnly
                                  />
                                </p>
                              </div>
                            </Stack>
                          </Box>

                          <Box sx={{ width: "190px" }}>
                            <Stack direction="column" spacing={1}>
                              <Typography>
                                Date: {sessElement ? sessElement.date : "-"}
                              </Typography>
                              <Typography>
                                Time:{" "}
                                {sessElement
                                  ? sessElement.time_slot.commence
                                  : "-"}
                              </Typography>
                            </Stack>
                          </Box>
                        </Stack>
                        <Box sx={{ marginTop: "5px" }}>
                          <Typography>
                            Feedback:{" "}
                            {sessElement ? sessElement.review?.comment : ""}
                            {/* <TextField
                          fullWidth="true"
                          id="standard-basic"
                          sx={{ borderBottom: "1px solid #696984" }}
                          variant="standard"
                          type=
                        /> */}
                            {/* <TextField
                          id="standard-basic"
                          fullWidth="true"
                          sx={{ borderBottom: "1px solid #696984" }}
                          variant="standard"
                        /> */}
                          </Typography>
                        </Box>

                        {/* new changes 13 jan2023 */}
                        <CustomSessionNotes
                          id={sessElement?.id}
                          userId={element.id}
                          session_field={
                            sessElement?.session_notes?.session_field
                          }
                          callBack={handleCallBack}
                        />
                        <CustomStudentProfile
                          id={sessElement?.id}
                          userId={element.id}
                          stuProfile={objForForm?.extra_info?.student_profile
                          }
                        />
                        <CustomDreamCareer
                          id={sessElement?.id}
                          userId={element.id}
                          dream_career={objForForm?.extra_info?.dream_career}
                        />
                        <CustomMindDreamCareer
                          id={sessElement?.id}
                          userId={element.id}
                          mind_dream_career={objForForm?.extra_info?.mind_dream_career}
                        />
                        <CustomConcern
                          id={sessElement?.id}
                          userId={element.id}
                          area_concern={objForForm?.extra_info?.area_of_concern}
                        />
                        {/* <CustomJudgement
                          id={sessElement?.id}
                          userId={element.id}
                        />
                        <CustomRecommendation
                          id={sessElement?.id}
                          userId={element.id}
                        /> */}
                      </CardContent>
                    </Card>
                  );
                })}
              </Box>
            </Box>
          );
        })}
      </Box>
    );
  };

  const [listData, setlistData] = useState([]);

  useEffect(() => {
    getStudentsList(filterData.searchParam, filterData.grade, filterData.date);
  }, [pageState]);

  const [listLenght, setListLenght] = useState(null);
  const [paginationData, setPaginationData] = useState(1);
  const getStudentsList = (_search, _grade, _date) => {
    console.log("loistingdats", _date);
    // setLoading(true);
    // var url_param = "";
    // url_param = `${url_param}&grade=${_grade}`;
    // if (_grade === null || _grade === undefined || _grade === -1) {
    //   url_param = "";
    // }
    // if (_date !== null && _date !== "") {
    //   const { month, day, year } = yearMonthDayFormat(_date);
    //   url_param = `${url_param}&month=${month}&year=${year}&day=${day}`;
    // }

    // my code
    var search = _search === undefined ? "" : _search;
    setLoading(true);
    var url_param = "";
    if (_search) {
      url_param = `phone=${search}`;
    }
    if (_grade) {
      url_param =
        url_param === "" ? `grade=${_grade}` : `${url_param}&grade=${_grade}`;
    }
    if (_date !== null && _date !== "") {
      // const { month, day, year } = yearMonthDayFormat(_date);
      var d = new Date(_date);

      var dd = d.getDate();
      var mm = d.getMonth() + parseInt(1);
      var yyyy = d.getFullYear();

      console.log("mont,date,yeard", yyyy, mm, dd);
      url_param =
        url_param === ""
          ? `dob=${yyyy}-${mm}-${dd}`
          : `${url_param}&dob=${yyyy}-${mm}-${dd}`;
    }
    // if()

    // `/counseling/v2/all_slots/view_students/?phone=${_search}${url_param}`

    token_api
      .get(
        `/counseling/v2/all_slots/view_students/?${url_param}&page=${pageState}`
      )
      .then((response) => {
        setLoading(false);

        setlistData(response.data.data);
        setListLenght(response.data.total_count);
        setPageState(response.data.page);
        setPaginationData(response.data);
      });
  };

  const { page, previous_page, max_pages, total_count, count } = paginationData;

  console.log("maxpage", max_pages);

  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleFilter = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
    if (name === "grade") {
      getStudentsList(filterData.searchParam, value, filterData.date);
    }
  };

  console.log(state, "SSSSSS");

  const handleMentalHealth = (id, data) => {
      console.log(id, data , "handleMentalHealth");
      setMentalHealthData(data)
      setOpenMental(true)
  }
  const onCloseMental = () => {
    setOpenMental(false)
  }
  return (
    <>
      {/* navbar added frp, rokhi */}
      <div className={styles.containerNav}>
        <Typography
          sx={{
            width: "8.625rem",
            height: "1.875rem",
            color: "#2F327D",
            fontSize: "1.25rem",
            fontWeight: "500",
          }}
        >
          Students: {listLenght}
        </Typography>

        <Box>
          <Button onClick={() => handleCreateSession("bookSlot")}>
            Add a Student
          </Button>
        </Box>
      </div>
      <Box className={styles.container}>
        <Box className={styles.mainBox} sx={{ backgroundColor: "#FBFBFB" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 3,
              marginTop: "1.5rem",
              width: "100%",
              margin: "10px",
            }}
          >
            <TextField
              id="standard-multiline-flexible"
              label="Search by Name, Phone, School, School Location"
              variant="outlined"
              style={{ width: "300px", marginRight: "20px" }}
              value={filterData.searchParam}
              name="searchParam"
              onChange={handleFilter}
              size="small"
            />
            {/* new search start here  */}
            <TextField
              id="outlined-select-currency"
              select
              label="Grade"
              value={filterData.grade}
              name="grade"
              defaultValue="All"
              onChange={handleFilter}
              size="small"
              style={{ width: "200px" }}
            >
              <MenuItem value={-1}>All</MenuItem>
              {gradeList.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.title}
                </MenuItem>
              ))}
            </TextField>
            {/* <DatePicker
              selected={filterData.date}
              placeholderText="DOB"
              dateFormat="dd/MM/yyyy"
              onChange={(date) => handleDate(date)}
            /> */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="DOB"
                inputFormat="MM/DD/YYYY"
                value={valueD}
                onChange={(e) => handleDate(e)}
                renderInput={(params) => (
                  <TextField size="small" name="dob" {...params} />
                )}
              />
            </LocalizationProvider>
          </Box>

          {/* table starts here */}

          <TableContainer
            sx={{ display: "block", width: "95%", margin: "auto" }}
            className={styles.table}
          >
            <Table>
              {/* <Box sx={{backgroundColor:'#428df5'}}> */}
              <TableHead
                sx={{
                  backgroundColor: "#428df5",
                  width: "100%",
                }}
              >
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      borderBottom: "none",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    Sr No
                    {/* <div></div> */}
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      borderBottom: "none",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    Name
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      borderBottom: "none",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    Parent Name
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      borderBottom: "none",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    Class
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      borderBottom: "none",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    School
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      borderBottom: "none",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    School Location
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      borderBottom: "none",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    Gender
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      borderBottom: "none",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    DOB
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      borderBottom: "none",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    Phone
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      borderBottom: "none",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    Career Counselling
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      borderBottom: "none",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    Mental Health
                  </TableCell>
                </TableRow>
              </TableHead>
              {/* </Box> */}

              {/* lopping the elements */}
              {/* table body starts here */}
              <TableBody>
                {listData.map((e, index) => {
                  console.log("pppppppwwww", e);
                  return (
                    <TableRow
                      key={index}
                      sx={{ backgroundColor: "#FBFBFB" }}
                      className={styles.hoverTable}
                    >
                      <TableCell
                        onClick={toggleDrawer("right", true, e.id, e)}
                        align="center"
                        sx={{ color: "black" }}
                      >
                        {index + 1}.
                      </TableCell>
                      <TableCell
                        onClick={toggleDrawer("right", true, e.id, e)}
                        align="center"
                        sx={{ color: "black" }}
                      >
                        {e.first_name + " " + e.last_name}
                      </TableCell>
                      <TableCell align="center" sx={{ color: "black" }}>
                        {e.guardian[0]
                          ? e.guardian[0]?.first_name +
                            " " +
                            `${
                              e.guardian[0]?.last_name == null
                                ? ""
                                : e.guardian[0]?.last_name
                            }`
                          : "-"}
                      </TableCell>
                      <TableCell align="center" sx={{ color: "black" }}>
                        {e.profile.grade ? e.profile.grade.title : "-"}
                      </TableCell>
                      <TableCell align="center" sx={{ color: "black" }}>
                        {instituteList[
                          instituteList.findIndex(
                            (item) => item.id == e.profile?.institute
                          )
                        ]?.name
                          ? instituteList[
                              instituteList.findIndex(
                                (item) => item.id == e.profile?.institute
                              )
                            ]?.name
                          : "-"}
                      </TableCell>

                      <TableCell align="center" sx={{ color: "black" }}>
                        {instituteList[
                          instituteList.findIndex(
                            (item) => item.id == e.profile?.institute
                          )
                        ]?.location
                          ? instituteList[
                              instituteList.findIndex(
                                (item) => item.id == e.profile?.institute
                              )
                            ]?.location
                          : "-"}
                      </TableCell>
                      <TableCell align="center" sx={{ color: "black" }}>
                        {
                          gender[
                            gender.findIndex((item) => item.id == e.gender)
                          ]?.name
                        }
                      </TableCell>
                      <TableCell align="center" sx={{ color: "black" }}>
                        {e.profile.dob ? e.profile.dob : "-"}
                      </TableCell>
                      <TableCell align="center" sx={{ color: "black" }}>
                        {e.phone ? e.phone : "-"}
                      </TableCell>
                      <TableCell align="center" sx={{ color: "black" }}>
                        <Button
                          variant="contained"
                          size="small"
                          sx={{ minWidth: "115px" }}
                          style={{
                            backgroundColor: "#32c6b",
                          }}
                          onClick={() =>
                            handleCareerCounsellingReport(
                              e.id,
                              e?.student_test_type
                            )
                          }
                        >
                          Download Report
                        </Button>
                      </TableCell>
                      <TableCell align="center" sx={{ color: "black" }}>
                        <Button
                          variant="contained"
                          size="small"
                          style={{
                            backgroundColor: "#32c6b",
                          }}
                          sx={{ minWidth: "115px" }}
                          onClick={() =>
                            handleMentalHealth(
                              e.id,
                              e
                            )
                          }
                        >
                          VIEW RESULT
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Pagination
          count={max_pages}
          size="large"
          variant="outlined"
          shape="rounded"
          onChange={(e, value) => setPageState(value)}
        />

        <CustomSnackBar
          message={snackbarData.message}
          openSnack={snackbarData.open}
          severity={snackbarData.responseType}
        />
        {loading && listData.length === 0 && (
          <p>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </p>
        )}

        {!loading && listData.length === 0 && (
          <p style={{ textAlign: "center" }}>
            <b>No Students Found</b>
          </p>
        )}
        {/* more info page starts here */}

        <div>
          {["right"].map((anchor) => (
            <React.Fragment key={anchor}>
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
              >
                {list(anchor, stateID, objForForm)}
              </Drawer>
            </React.Fragment>
          ))}
        </div>

        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <EditFieldsForm
            handleClose={handleClose}
            editSection={sectionType}
            editFormDetails={editObject}
            objForForm={objForForm}
          />
        </BootstrapDialog>

        {/* add student diag */}

        <Dialog
          open={dataAddStu}
          // onClose = {() => setData(false)   }
        >
          <DialogTitle>Add Student Details</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div>
                <Typography
                  sx={{
                    fontSize: "20px",
                    marginTop: "5px",
                    marginBottom: "10px",
                  }}
                >
                  Student Personal Details
                </Typography>
                <Grid container component="form" spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      error={checkUserName}
                      required
                      id="outlined-required"
                      label="First Name"
                      size="small"
                      name="first_name"
                      value={registerStudent.first_name}
                      onChange={(e) => handleChangeAdd(e, "userObject")}
                      helperText={
                        checkUserName ? "Name can only contain characters" : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={checkUserName}
                      required
                      id="outlined-required"
                      label="Last Name"
                      size="small"
                      name="last_name"
                      value={registerStudent.last_name}
                      onChange={(e) => handleChangeAdd(e, "userObject")}
                      helperText={
                        checkUserName ? "Name can only contain characters" : ""
                      }
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      required
                      error={checkNumber}
                      id="outlined-required"
                      label="Phone"
                      size="small"
                      name="phone"
                      // type="number"
                      value={registerStudent.phone}
                      helperText={
                        checkNumber
                          ? "Phone number must contain only 10 digits"
                          : ""
                      }
                      onChange={(e) => handleChangeAdd(e, "guardianObject")}
                      inputProps={{
                        maxLength: 10,
                      }}
                    />

                    <div>
                      <small style={{ color: "red", marginBottom: "10px" }}>
                        {alreadyRegister ? "User already exists" : ""}
                      </small>
                      <small style={{ color: "red", marginBottom: "10px" }}>
                        {alreadyRegister == false ? "New User" : ""}
                      </small>
                    </div>
                  </Grid>

                  {/* <Typography
                    sx={{
                      marginLeft: "17px",
                      marginTop: "19px",
                    }}
                  > */}

                  <Grid item xs={6}>
                    {/* <DatePicker
                      // selected={filterData.date}
                      className={styles.dateStyleGG}
                      required
                      placeholderText="DOB"
                      // dateFormat="dd/MM/yyyy"
                      value={profileDetails.dob}
                      onChange={(date) => handleChangeProfileDate(date)}
                    /> */}

                    <DatePicker
                      // value={profileDetails.dob}
                      // selected={profileDetails.dob}
                      placeholderText={correctDate ? correctDate : "Dob"}
                      onChange={(date) => handleChangeProfileDate(date)}
                      // onCalendarClose={handleCalendarClose}
                      // onCalendarOpen={handleCalendarOpen}
                    />
                    <small style={{ color: "red", marginBottom: "10px" }}>
                      {checkDob ? "Enter Dob" : ""}
                    </small>
                  </Grid>

                  {/* </Typography> */}

                  <Grid item xs={6}>
                    <TextField
                      error={checkMail}
                      id="outlined-required"
                      label="Email"
                      size="small"
                      name="email"
                      value={registerStudent.email}
                      helperText={checkMail ? "Enter Valid Email address" : ""}
                      onChange={(e) => handleChangeAdd(e, "guardianObject")}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl
                      error={checkGrade}
                      fullWidth
                      helperText="has error"
                    >
                      <InputLabel
                        // sx={{ top: "-7px" }}
                        size="small"
                        id="demo-simple-select-label"
                      >
                        {/* {profileDetails.grade_id ? "" : "Grade"} */}
                        Grade
                      </InputLabel>
                      <Select
                        required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={profileDetails.grade_id}
                        label="Grade"
                        name="grade_id"
                        onChange={(e) => handleChangeProfile(e, "userObject")}
                        style={{ width: "90%" }}
                        size="small"
                      >
                        {gradeList.map((item, idx) => {
                          return (
                            <MenuItem value={item.id}>
                              <em className={styles.listAlignStyles}>
                                {item.title}
                              </em>
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {checkGrade && (
                        <FormHelperText>Grade is required!</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl error={checkInstitute} fullWidth>
                      <InputLabel
                        // sx={{ top: "-7px" }}
                        size="small"
                        id="demo-simple-select-label"
                      >
                        {/* {profileDetails.institute ? "" : "Institute"} */}
                        Institute
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-disabled-label"
                        id="demo-simple-select-disabled"
                        // value={userObject.institute}
                        label="Institute"
                        name="institute"
                        value={profileDetails.institute}
                        onChange={(e) => handleChangeProfile(e, "userObject")}
                        style={{ width: "90%" }}
                        size="small"
                      >
                        {instituteList.map((item, idx) => {
                          return (
                            <MenuItem value={item.id}>
                              <em className={styles.listAlignStyles}>
                                {item.name}
                              </em>
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {checkInstitute && (
                        <FormHelperText>Institute is required!</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label">
                        Gender
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        // defaultValue="female"
                        value={registerStudent.gender}
                        name="gender"
                      >
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="Female"
                          onChange={(e) => handleChangeAdd(e, "userObject")}
                        />
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Male"
                          onChange={(e) => handleChangeAdd(e, "userObject")}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>

              {/* 2nd section */}

              <div>
                <Typography
                  sx={{
                    fontSize: "20px",
                    marginTop: "5px",
                    marginBottom: "10px",
                  }}
                >
                  Guardian Information{" "}
                  {/* <span>
                    <AddIcon />
                  </span> */}
                </Typography>

                <Grid container component="form" spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      required
                      error={checkGname}
                      id="outlined-required"
                      label="First Name"
                      size="small"
                      name="first_name"
                      value={guardianDetails.first_name}
                      onChange={(e) =>
                        handleChangeGuardian(e, "guardianObject")
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={checkGname}
                      id="outlined-required"
                      label="Last Name"
                      size="small"
                      name="last_name"
                      value={guardianDetails.last_name}
                      onChange={(e) =>
                        handleChangeGuardian(e, "guardianObject")
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      error={checkGphone}
                      id="outlined-required"
                      label="Phone"
                      size="small"
                      name="phone"
                      type="number"
                      pattern="[1-9]{1}[0-9]{9}"
                      value={guardianDetails.phone}
                      onChange={(e) =>
                        handleChangeGuardian(e, "guardianObject")
                      }
                      helperText={
                        checkGphone
                          ? "Phone number must contain only 10 digits"
                          : ""
                      }
                      inputProps={{
                        maxLength: 10,
                      }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      error={checkGeMail}
                      id="outlined-required"
                      label="Email"
                      size="small"
                      name="email"
                      value={guardianDetails.email}
                      // defaultValue={guardianDetails.email}

                      onChange={(e) =>
                        handleChangeGuardian(e, "guardianObject")
                      }
                      helperText={
                        checkGeMail ? "Enter valid email address " : ""
                      }
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      id="outlined-required"
                      label="Occupation"
                      size="small"
                      name="occupation"
                      value={guardianDetails.occupation}
                      onChange={(e) =>
                        handleChangeGuardian(e, "guardianObject")
                      }
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      id="outlined-required"
                      label="Education"
                      size="small"
                      name="education"
                      value={guardianDetails.education}
                      onChange={(e) =>
                        handleChangeGuardian(e, "guardianObject")
                      }
                    />
                  </Grid>

                  {/* <Grid item xs={6}>
                    <TextField
                      id="outlined-required"
                      label="Relation"
                      size="small"
                      name="relationship"
                      value={guardianDetails.relation}
                      onChange={(e) =>
                        handleChangeGuardian(e, "guardianObject")
                      }
                    />
                  </Grid> */}
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel
                        sx={{ top: "-7px" }}
                        id="demo-simple-select-label"
                      >
                        {guardianDetails.relationship ? "" : "Relationship"}
                      </InputLabel>

                      <Select
                        labelId="demo-simple-select-label"
                        // id="demo-simple-select-disabled"
                        // value={userObject.institute}
                        label="Relationship"
                        name="relationship"
                        value={guardianDetails.relationship}
                        onChange={(e) =>
                          handleChangeGuardian(e, "guardianObject")
                        }
                        style={{ width: "90%" }}
                        size="small"
                      >
                        {relation.map((item, idx) => {
                          return (
                            <MenuItem value={item.id}>
                              <em>{item.title}</em>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button type="submit" onClick={() => handleStuPost()}>
              Save
            </Button>
            <Button onClick={() => addstuAndPayAndBook()}>
              Save and Book{" "}
            </Button>
            <Button onClick={addstuAndPayClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={paymentState}
          width="35rem"
          sx={{
            width: "35rem",
            // position:"relative",
            // left:"60%"
            margin: "auto",
          }}
        >
          <DialogTitle>Book Session</DialogTitle>
          <DialogContent sx={{ width: "45rem" }}>
            <DialogContentText>
              <div>
                <Typography>Book Counselling Slot</Typography>
                <div>
                  <Typography
                    sx={{
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    <Button
                      // { nowCheck && disabled=true }
                      disabled={nowCheck && true}
                      className={
                        colButton === true
                          ? styles.makeBtnBlue
                          : styles.makeBtnGreen
                      }
                      onClick={handleNowTime}
                    >
                      {colButton === true ? <DoneIcon /> : "Now"}
                    </Button>
                  </Typography>

                  <Typography>
                    <DatePicker
                      // selected={filterData.date}
                      disabled={colButton}
                      value={bookingDate}
                      placeholderText="Select Date"
                      // dateFormat="dd/MM/yyyy"
                      onChange={(date) => addStuDate(date)}
                    />
                  </Typography>

                  <div className={styles.timeSlotStyles}>
                    {dates &&
                      dates.map((data, idx) => (
                        <Typography sx={{ margin: "5px" }} key={idx}>
                          <Button
                            className={`${
                              data.instructor_slots[0]?.is_available == true
                                ? styles.makeBtnGreen
                                : styles.makeBtnGray
                            } ${
                              data == bookslotData ? styles.styleForClick : null
                            }`}
                            onClick={() => setBookSlotData(data)}
                            disabled={
                              data.instructor_slots[0]?.is_available
                                ? false
                                : true
                            }
                          >
                            {data == bookslotData ? (
                              <DoneIcon />
                            ) : (
                              moment(data.commence, "HH:mm:ss ").format(
                                "hh:mm A"
                              )
                            )}
                          </Button>
                        </Typography>
                      ))}
                  </div>
                </div>
              </div>

              <div className={styles.payDetDiv}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Payment Method
                  </InputLabel>
                  <Select
                    error={payAlert}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={handleChangePayment}
                    label="Payment Method"
                    className={styles.payStyles}
                    padding="4px"
                    helperText={payAlert ? "Enter payment method" : ""}
                  >
                    <MenuItem value={"cash"}>Cash</MenuItem>
                    <MenuItem value={"upi"}>UPI</MenuItem>
                    <MenuItem value={"neft"}>NEFT</MenuItem>
                    <MenuItem value={"cheque"}>Cheque</MenuItem>
                  </Select>
                </FormControl>
              </div>

              {noCash ? (
                <div className={styles.payDetDiv}>
                  <TextField
                    error={valTrans}
                    id="outlined-basic"
                    label="Transaction Id"
                    variant="outlined"
                    helperText={valTrans ? "Enter transaction id " : ""}
                    onChange={(e) => handlePutTransID(e)}
                  />
                </div>
              ) : (
                ""
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleBookingFree(bookslotData)}>
              Book
            </Button>
            <Button onClick={addstuAndPayCloseAfterPayment}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </Box>
      <DrawerMentalHealth open={opneMental} studentData={mentalHealthData} onClose={onCloseMental}/>
    </>
  );
}

export default Students;
