import { Button, TextField } from "@mui/material";
import React from "react";
import styles from "./dream.module.css";
import { useState } from "react";
import { token_api } from "../../Utils/Network";
// import Button from "@mui/material";
import { useEffect } from "react";

import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import DreamDict from "./DreamDict";

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
const mic = new SpeechRecognition();

mic.continuous = true;
mic.interimResults = true;
mic.lang = "en-US";

const CustomDreamCareer = ({ userId, dream_career, id, ...props }) => {
  // const { transcript, resetTranscript } = useSpeechRecognition();
  const [isListening, setIsListening] = useState(false);
  const [edit, setEdit] = useState(true);
  const [note, setNote] = useState(null);
  const [dreamFieldDict, setDreamFieldDict] = useState(null);
  // useEffect(() => {
  //   setStudentDream(transcript);
  // }, [transcript]);
  const handleSpeechDataDream = (speechToText) => {
    console.log("SpeechTotextinMain", speechToText);
    setDreamFieldDict(speechToText);
  };

  useEffect(() => {
    handleListen();
  }, [isListening]);

  const handleListen = () => {
    if (isListening) {
      mic.start();
      mic.onend = () => {
        console.log("continue..");
        mic.start();
      };
    } else {
      mic.stop();
      mic.onend = () => {
        console.log("Stopped Mic on Click");
      };
    }
    mic.onstart = () => {
      console.log("Mics on");
    };

    mic.onresult = (event) => {
      const transcript = Array.from(event.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join("");
      console.log(transcript);
      setNote(transcript);
      setStudentDream(transcript);
      mic.onerror = (event) => {
        console.log(event.error);
      };
    };
  };

  // console.log("DReamLog", userId, id, dream);
  const [card, setCard] = useState(false);

  const [studentDream, setStudentDream] = useState("");
  const handleStudentDreamSubmit = (e) => {
    e.preventDefault();
    setCard(true);
    let data = {};

    data = {
      user_id: userId,
      extra_information: {
        session: id,
        dream_career: studentDream,
      },
    };

    token_api
      .post(
        `/counseling/add/student/update_student_profile/?user=${userId}`,
        data
      )
      .then((response) => {
        console.log("CheckingResponseafterpost", response.data.data);
        // setStudentDream(response?.data?.data?.dream)
      })
      .catch((err) => {
        console.log(err);
      });
    setEdit(true);

    console.log("Hitbeforeapi", data);
  };

  const toggleEdit = () => {
    setCard(false);
    setEdit(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    // DISPATCH

    // setStudentDream({
    //   ...studentDream,
    //   [name]: value,
    // });
    setStudentDream(value);
  };

  useEffect(() => {
    setStudentDream(dream_career);

    {
      dream_career && setCard(true);
    }
  }, []);

  // console.log("studentProfilew", studentDream);
  return (
    <>
      <div className={styles.cardContainer}>
        <div className={styles.editStyle}>
          <span className={styles.font20}>Dream Career</span>

          {isListening == false ? (
            <MicOffIcon
              sx={{ color: "#f71302" }}
              onClick={() => setIsListening((prevState) => !prevState)}
            />
          ) : (
            <MicIcon
              sx={{ color: "#02f733" }}
              onClick={() => setIsListening((prevState) => !prevState)}
            />
          )}
          {/* <Button
            size="small"
            onClick={() => setIsListening((prevState) => !prevState)}
          >
            {" "}
            Start/Stop Mic
          </Button> */}

          <div style={{ display: "flex" }}>
            {/* {dreamFieldDict === "dream" ? (
              <DreamDict speechData={handleSpeechDataDream}  />
            ) : (
              <center>
                <MicIcon onClick={() => setDreamFieldDict("dream")} />
              </center>
            )} */}
            {/* <span onClick={SpeechRecognition.startListening}>
              <MicIcon />
            </span>

            <span onClick={SpeechRecognition.stopListening}>
              <MicOffIcon />
            </span> */}

            {edit && (
              <span style={{ cursor: "Pointer" }} onClick={toggleEdit}>
                Edit
              </span>
            )}
          </div>
        </div>
        {card == false ? (
          <>
            <div>
              <form onSubmit={handleStudentDreamSubmit}>
                <TextField
                  fullWidth
                  //   id="outlined-required"
                  //   label="Required"

                  onChange={handleChange}
                  name="student_dream"
                  value={studentDream}
                  multiline
                  required
                  rows={4}
                />
                <Button
                  style={{
                    position: "relative",
                    left: "82%",
                    marginTop: "10px",
                  }}
                  type="submit"
                  size="small"
                  variant="contained"
                >
                  Save
                </Button>
              </form>
            </div>
          </>
        ) : (
          <>
            <div className={styles.showCard}>
              {studentDream && studentDream}
            </div>
          </>
        )}

        {/* show card */}
      </div>
    </>
  );
};

export default CustomDreamCareer;
